// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
// ** UTILS **
import { ModalWrapper, ModalContent, ModalOverlay, Button, ImgLeft, ImgRight, Close, Title } from "../Style/StyledComponents";
import Api from "../../contexts/Api";
//
// ** PNG **
import confettis from '../../assets/static/confettis.png'
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const LevelUp = (opt) => {

  const { onClose } = opt
  const next_level = opt.next_level || []
  const type = next_level.length ? 1 : 2
  const array = next_level.length ? 'levelUp' : 'sponsor'

  const handleData = () => {
    let data = { array: array }
    if (type === 1) {
      data.reward_id = next_level[0].reward_id
    } else {
      data.offer_id = opt.sponsor[0].offer_id
      data.sponsored_id = opt.sponsor[0].sponsored_id
    }
    onClose(data);
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <ModalWrapper>
    <ModalOverlay onClick={handleData} />
    <S.ModalContent>
      <Close onClick={handleData}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M313 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L41 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L7 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L279 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L313 137z" /></svg>
      </Close>
      <S.Title as='h2'>{type === 1 ? 'Niveau suivant !' : 'Bravo !'}</S.Title>
      {type === 1 && <S.LevelContainer>
        <S.Level next={false}>
          Niv
          <span>{next_level.length && next_level[0].label - 1}</span>
        </S.Level>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z" /></svg>
        <S.Level next={true}>
          Niv
          <span>{next_level.length && next_level[0].label}</span>
        </S.Level>
      </S.LevelContainer>}
      <S.RewardInfos>
        {type === 1 ? 'Joli ! Vous remportez' : 'Votre relation a été embauchée, vous remportez'}
        <span>{type === 1 ? next_level.length && next_level[0].reward : opt.sponsor[0].reward}{(type === 2 || (next_level && !isNaN(Number(next_level[0].reward)))) && <span>&#8364;</span>}</span>
        <br /><br />Pour obtenir votre gain, contactez votre agence Satis
      </S.RewardInfos>
      <Button onClick={handleData}>Super !</Button>
      <ImgLeft src={confettis} alt='confettis left' />
      <S.ImgRight src={confettis} alt='confettis right' />
    </S.ModalContent>
  </ModalWrapper>
};

export default LevelUp;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.ModalContent = styled(ModalContent)`
display: flex;
flex-direction: column;
align-items: center;
`

S.Title = styled(Title)`
color: ${({ theme }) => theme.third};
`

S.LevelContainer = styled.div`
display: flex;
align-items: center;
gap: 10px;

& svg {
  width: 18px;
  height: 24px;
  fill: ${({ theme }) => theme.grey};
}
`

S.Level = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: ${props => props.next ? '80' : '47'}px;
height: ${props => props.next ? '80' : '47'}px;
background: ${({ theme, next }) => next ? theme.primary : theme.grey};
color: ${({ theme }) => theme.textSecondary};
border-radius: 50%;
font-size: ${props => props.next ? '20' : '12'}px;

& span {
  font-size: ${props => props.next ? '30' : '20'}px;
  font-weight: 700;
}
`

S.RewardInfos = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin: 20px 0;
gap: 10px;
color: ${({ theme }) => theme.third};
font-size: 15px;

& span {
  font-size: 30px;
  font-weight: 700;

  & span {
    color: ${({ theme }) => theme.textPrimary};
  }
}
`

S.ImgRight = styled(ImgRight)`
transform: scaleX(-1);  
`