// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import Cookies from 'universal-cookie';
import { useLocation, Link, useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
// 
// ** UTILS **
import Api from "../../contexts/Api";
// 
// ** COMPONENTS **
import { EmptyContainer, LinkAnimated, Offer, Spinner } from "../../components/Style/StyledComponents";
import Share from "../../components/Share";
import SearchBar from "../../components/SearchBar/SearchBar";
import { breakpoints } from "../../components/Style/StyledBreakpoints";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const List = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offers, setOffers] = useState({ all: [], filtered: [] });
  const [empty, setEmpty] = useState(false);
  const offset = useRef(0);
  const [loading, setLoading] = useState(true);
  // SEARCH VALUES
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState('');
  const [searchZipCode, setSearchZipCode] = useState('');
  const [searchRadius, setSearchRadius] = useState(0);

  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  const location = useLocation();

  const { ref, inView } = useInView();

  // 
  // ─── FETCH OFFERS ───────────────────────────────────────
  //
  const fetchOffers = async ({ infinite }) => {
    setEmpty(false);
    let query = {
      offset: offset.current,
      limit: true,
    }

    if (userData.role === 2) query.display = 1;

    searchParams.forEach((value, key) => {
      if (key !== 'label') {
        query[key] = value
      }
    });

    const data = await Api({
      endpoint: '/offers',
      query,
      method: 'GET'
    });

    if (data.success) {
      if (infinite) {
        if (searchParams.toString().length) setOffers(prevOffers => ({ all: [], filtered: [...prevOffers.filtered, ...data.data] }));
        else setOffers(prevOffers => ({ all: [...prevOffers.all, ...data.data], filtered: [] }));
      } else {
        if (searchParams.toString().length) setOffers({ all: [], filtered: [...data.data] });
        else setOffers({ all: [...data.data], filtered: [] });
      }
    } else {
      if (infinite) {
        if (offers.filtered.length) setOffers(prevOffers => ({ all: [...prevOffers.all], filtered: [...prevOffers.filtered] }));
        else setOffers(prevOffers => ({ all: [...prevOffers.all], filtered: [] }));
      } else {
        setOffers({ all: [], filtered: [] });
      }
      setEmpty(true)
    }
    setLoading(false)
  }

  //
  // ─── HANDLE SEARCH & FILTERS ───────────────────────────────────────
  //
  const handleSearch = (opt) => {
    let key = Object.keys(opt)[0]
    let value = Object.values(opt)

    offset.current = 0
    setOffers({ all: [], filtered: [] })

    if (value[0] === false || value[0].length === 0 || value[0] === 0 || value[0] === '0') {
      setSearchValue('')
      setSearchZipCode('')
      setSearchRadius(0)
      searchParams.delete('label')
      searchParams.delete('radius')
      searchParams.delete(key)
      setSearchParams(searchParams)
    } else if (key === 'title') {
      setSearchValue(value)
    } else if (key === 'zip_code') {
      setSearchZipCode(value[0])
      setSearchParams(params => { params.set('label', value[1]); return params })
    } else if (key === 'radius') {
      setSearchRadius(value)
    } else {
      setSearchParams(params => {
        params.set(key, value)
        return params
      })
    }
  }

  // Use effect à chaque changement de paramètres de recherche
  useEffect(() => {
    fetchOffers({ infinite: false })
  }, [location])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValue) {
        setSearchParams(params => {
          params.set('title', searchValue)
          return params
        })
      }
      if (searchZipCode) {
        setSearchParams(params => {
          params.set('zip_code', searchZipCode)
          return params
        })
      }
      if (+searchRadius[0] > 0 && searchZipCode) {
        setSearchParams(params => {
          params.set('radius', searchRadius)
          return params
        })
      }
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [searchValue, searchZipCode, searchRadius]);

  let offersList = offers.all
  if (offers.filtered && offers.filtered.length) offersList = offers.filtered

  useEffect(() => {
    if (inView && !empty && (offersList.length || 0) >= 1) {
      offset.current += 20;
      fetchOffers({ infinite: true });
    }
  }, [inView]);

  const filters = {
    contract_type: ['CDI', 'CDD', 'Intérim']
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <SearchBar defaults={searchParams} search={handleSearch} filters={filters} offers={true} filter={true} />
    {offersList && offersList.length ? <S.OffersContainer id="offersContainer">
      {offersList.map(offer => {
        return <S.Offer key={offer.offer_id}>
          <S.Title>
            <h2>{offer.title}</h2>
          </S.Title>
          <S.Description>{offer.description}</S.Description>
          <Details color='rgba(81, 81, 81, 0.5)'>
            <p>{offer.sector}</p>
            <span>&#8226;</span>
            <p>{offer.city}, {offer.postal_code}</p>
            <span>&#8226;</span>
            <p>{offer.reference}</p>
          </Details>
          <S.Bonus>
            <S.First>
              Bonus
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3zM609.3 512H471.4c5.4-9.4 8.6-20.3 8.6-32v-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2h61.4C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z" /></svg>
            </S.First>
            <S.Second>
              {offer.reward}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M48.1 240c-.1 2.7-.1 5.3-.1 8v16c0 2.7 0 5.3 .1 8H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H60.3C89.9 419.9 170 480 264 480h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264c-57.9 0-108.2-32.4-133.9-80H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H112.2c-.1-2.6-.2-5.3-.2-8V248c0-2.7 .1-5.4 .2-8H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H130.1c25.7-47.6 76-80 133.9-80h24c17.7 0 32-14.3 32-32s-14.3-32-32-32H264C170 32 89.9 92.1 60.3 176H32c-17.7 0-32 14.3-32 32s14.3 32 32 32H48.1z" /></svg>
            </S.Second>
          </S.Bonus>
          {userData.role === 1 && <S.Edit to={`/admin/offer/${offer.offer_id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z" /></svg>
          </S.Edit>}
          <LinkAnimated to={`/offer-detail/${offer.offer_id}`} className='seeMore'>
            Postuler
          </LinkAnimated>
          <S.ShareContainer>
            <Share url={`offer-detail/${offer.offer_id}`} sponsor={`?sponsor_code=${userData.sponsor_code}`} icon={true} />
          </S.ShareContainer>
        </S.Offer>
      })}
      {loading && <Spinner></Spinner>}
      {empty && <EmptyContainer onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
        <p>Retourner en haut de la page.</p>
      </EmptyContainer>}
      {offersList && offersList.length ? !loading ? empty ? <></> : <div ref={ref}></div> : <Spinner></Spinner> : <></>}
    </S.OffersContainer> : <>
      {empty && <EmptyContainer>
        <h1>
          Oops ...
        </h1>
        Aucune offre disponible pour le moment.
      </EmptyContainer>}
    </>
    }
  </>
};

export default List;

const S = {}
S.OffersContainer = styled.div`
display: flex;
width: 100%;
flex-direction: ${({ theme }) => theme.width > 800 ? 'row' : 'column'};
${({ theme }) => theme.width > 800 && 'flex-wrap: wrap'};
gap: 60px;
${breakpoints("gap", "px", [
  { 800: 50 },
], "min-width")};
`

S.Offer = styled(Offer)`
& h2 {
  font-size: 16px;
  font-weight: 800;
  color: ${({ theme }) => theme.primary};
  margin: 0;
}
`

S.Title = styled.div`
display: flex;
flex-direction: column;
flex-wrap: wrap; 
width: 90%;
`

S.Edit = styled(Link)`
display: flex;
position: absolute;
right: 110px;
bottom: -12px;
justify-content: center;
align-items: center;
background: ${({ theme }) => theme.primary};
border-radius: 50%;
width: 30px;
height: 30px;

& svg {
  width: 14px;
  height: 14px;
  fill: ${({ theme }) => theme.secondary};
}
`

S.Bonus = styled.div`
display: flex;
position: absolute;
left: -5px;
bottom: -12px;
border-radius: 29px;
border: 2px solid ${({ theme }) => theme.primary};
width: fit-content;
background: ${({ theme }) => theme.secondary};


& div {
  display: flex;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 13px;
  align-items: center;
  padding: 5px 10px;
  gap: 5px;
}

& svg {
  width: 15px;
  height: 15px;
}
`

S.First = styled.div`
background: ${({ theme }) => theme.primary};
color: ${({ theme }) => theme.secondary};
border-radius: 19px / 29px;
& svg {
  fill: ${({ theme }) => theme.secondary};
}
`
S.Second = styled.div`
background: ${({ theme }) => theme.secondary};
color: ${({ theme }) => theme.gold};
border-radius: 15px;
& svg {
  fill: ${({ theme }) => theme.gold};
}
`

S.Description = styled.p`
-webkit-line-clamp: 3;
-webkit-box-orient: vertical;
display: -webkit-box;
font-size: 13px;
overflow: hidden;
margin: 10px 0;
`

export const Details = styled.div`
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
font-size: 13px;
color: ${props => props.color};

 & p:first-of-type {
  color: ${({ theme }) => theme.primary};
  font-weight: 700;
 }
`

S.ShareContainer = styled.div`
position: absolute;
top: -10px;
right: -10px;
`