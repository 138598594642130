// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import styled from "styled-components/macro";
//
// ** COMPONENTS **
import { Title, Container, Number, RewardsContainer, Reward, ImgLeft, ImgRight } from "../Style/StyledComponents";
import Loading from "../Loading";
//
// ** PNG **
import blocked from '../../assets/static/cadenas.png'
import unblocked from '../../assets/static/débloqué.png'
import confettis from '../../assets/static/confettis.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListRewards = ({ rewards, loading, user }) => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <S.Container>
    <Title as="h2">
      Récompenses
    </Title>
    <Loading loading={loading}>
      {rewards.length && <RewardsContainer>
        {rewards.sort((a, b) => a.label - b.label).map((reward, index) => {
          const is_unblocked = user?.find(data => data.reward_id === reward.reward_id) || {}
          const is_blocked = Object.values(is_unblocked).length ? true : false

          return <S.Reward key={index}>
            <Number>
              Niv
              <span>{reward.label}</span>
            </Number>
            <S.Blocked noblocked={is_blocked}>
              <img src={is_blocked ? unblocked : blocked} alt='locked' />
              <span>{is_blocked ? 'Débloqué' : 'Bloqué'}</span>
              {reward.reward}
            </S.Blocked>
            {is_blocked && <S.Imgs>
              <ImgLeft src={confettis} alt='confettis left' />
              <S.ImgRight src={confettis} alt='confettis right' />
            </S.Imgs>}
          </S.Reward>
        })}
      </RewardsContainer>
      }
    </Loading>
  </S.Container>
};

export default ListRewards;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Container = styled(Container)`
padding: 0
`

S.Reward = styled(Reward)`
max-width: ${({ theme }) => theme.width > 800 ? '15%' : '35%'};
`

S.Blocked = styled.div`
display: flex;
flex-direction: column;
align-items: center;
gap: 10px;
font-size: ${props => props.noblocked ? "27" : "14"}px;
font-weight: 700;

& span {
font-size: 12px;
font-weight: 900;
text-transform: uppercase;
color: ${({ theme }) => theme.textPrimary};
}

& img {
  ${props => props.noblocked && "margin-left: 10px"}
}

`
S.Imgs = styled.div`
& img {
  width: 43px;
  height: 43px;
  bottom: 0;
}

& img:first-child {
  left: 0;
}

`

S.ImgRight = styled(ImgRight)`
transform: scaleX(-1); 
right: 0;
`