// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
// 
// ** UTILS **
import Api from "../../contexts/Api";
//
// ** COMPONENTS **
import { AdminComponent, AdminUsersContainer, Button, EmptyContainer } from "../Style/StyledComponents";
import EditOfferUser from "../Modals/Admin/EditOfferUser";
import SearchBar from "../SearchBar/SearchBar";
import Loading from "../Loading";
import { breakpoints } from "../Style/StyledBreakpoints";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ApplyList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offers, setOffers] = useState({ all: [], filtered: [] })
  const [openModalOffers, setOpenModalOffers] = useState({ open: false, offer: null, name: null, sponsor_code: null })
  const [empty, setEmpty] = useState(false)
  const [loading, setLoading] = useState(true)
  const [offersList, setOffersList] = useState([]);

  // 
  // ─── GET OFFERS APPLIED USERS ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersOffers = async () => {
      const data = await Api({
        endpoint: '/offers_applied',
        query: { fields: ['offers.title', 'offers.offer_id', 'offers_applied.status', 'offers_applied.user_id', 'users.name', 'users.firstname', 'users.sponsored_code'], join: true, joinUsers: true },
        method: 'GET'
      });

      if (data.success) setOffers({ all: data.data, filtered: [] })
      else setEmpty(true)
      setLoading(false)
    }
    if (!offers.all.length) getUsersOffers()
  }, [offers])

  //
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const handleSearch = (value) => {
    let search = offers.all.filter(user => {
      if (!user.name) user.name = ''
      if (!user.firstname) user.firstname = ''
      if (!user.title) user.title = ''
      //search by nom prenom + offer title
      return (
        user.firstname.toLowerCase().includes(value) ||
        user.name.toLowerCase().includes(value) ||
        user.title.toLowerCase().includes(value)
      )
    })
    if (!search.length) search = [{ none: true }]
    setOffers({ ...offers, filtered: search })
  }

  //
  // ─── HANDLE OFFERS ───────────────────────────────────────
  //
  const handleOffers = (obj) => {
    const offer = offers.all.find(offer => offer.user_id === obj.user_id && offer.offer_id === obj.offer_id)
    offer.status = obj.status
    if (obj.status === 3) {
      const filteredArray = offers.all.filter(item => {
        return (
          item.user_id !== obj.user_id ||
          item.offer_id === obj.offer_id
        )
      })
      if (offers.filtered.length) {
        const filteredArray2 = offers.filtered.filter(item => {
          return (
            item.user_id !== obj.user_id ||
            item.offer_id === obj.offer_id
          )
        })
        setOffers({ all: filteredArray, filtered: filteredArray2 });
      } else setOffers({ all: filteredArray, filtered: [] });
    }
    setOpenModalOffers({ open: false, offer: null, sponsor_code: null });
  }

  useEffect(() => {
    const offersList = offers.filtered.length ? offers.filtered : offers.all
    setOffersList(offersList)
  }, [offers])

  //const offersList = offers.filtered.length ? offers.filtered : offers.all

  console.log('offers', offers)
  console.log('offersList', offersList)

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <AdminComponent>
    <S.Title>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M176 56V96H336V56c0-4.4-3.6-8-8-8H184c-4.4 0-8 3.6-8 8zM128 96V56c0-30.9 25.1-56 56-56H328c30.9 0 56 25.1 56 56V96v32V480H128V128 96zM64 96H96V480H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64zM448 480H416V96h32c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64z" /></svg>
      Postulations à valider
    </S.Title>
    <SearchBar disabled={true} users={true} change={handleSearch} />
    <Loading loading={loading}>
      <S.ApplyContainer>
        {offersList.sort((a, b) => a.status - b.status).map((offer) => {
          return offer.none ? <EmptyContainer>Pas d'offres trouvées.</EmptyContainer> : offer.status !== 4 && <S.AdminUsersContainer status={offer.status === 2} key={offer.offer_id + offer.user_id} onClick={() => offer.status === 2 && setOpenModalOffers({ open: true, offer: offer, sponsor_code: offer.sponsored_code })}>
            <div className="first_row">
              <div className="infosUser">
                <span>Nom</span>
                {offer.name ? offer.name : '-'}&nbsp;
                {offer.firstname ? offer.firstname : '-'}
              </div>
              <div className="infosUser">
                <span>Poste</span>
                {offer.title ? offer.title : '-'}
              </div>
            </div>
            <div className="validate">{offer.status === 2 ? <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-111 111-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L369 209z" /></svg> : <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z" /></svg>}</div>
          </S.AdminUsersContainer>
        })
        }
      </S.ApplyContainer>
    </Loading>
    {empty && <EmptyContainer>
      <h1>
        Oops ...
      </h1>
      Pas d'offres à valider pour le moment.
    </EmptyContainer>}
    {/* MODAL CHANGE OFFER STATUS */}
    {openModalOffers.open && <EditOfferUser offer={openModalOffers.offer} sponsor_code={openModalOffers.sponsor_code} onClose={() => setOpenModalOffers({ open: false, offer: null, sponsor_code: null })} onSubmit={(obj) => handleOffers(obj)} />}
  </AdminComponent>
};

export default ApplyList;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Title = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: ${({ theme }) => theme.textSecondary};;
font-size: 20px;
font-weight: 700;

& svg {
  fill: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
}

& button {
  margin-left: ${({ theme }) => theme.width > 800 ? '40px' : 'auto'};
}
`

S.ApplyContainer = styled.div`
width: 100%;
display: flex;
flex-wrap: wrap;
gap: 10px;
max-height: 600px;
overflow-y: auto;
padding-right: 10px;

&::-webkit-scrollbar {
  width: 3px;
}

&::-webkit-scrollbar-thumb {
  background: #F18903;
  border-radius: 5px;
}
`

S.AdminUsersContainer = styled(AdminUsersContainer)`
width: 43%;
${breakpoints("width", "", [
  { 1200: "90%" },
])};
color: ${props => props.status ? props.theme.third : props.theme.textSecondary};
background: ${props => props.status ? props.theme.secondary : 'transparent'};

  & .first_row {
    width: 100%;
    display: flex;

    & .infosUser {
      font-size: ${props => props.status ? 15 : 13}px;
    }
    
  }

 & .validate {
    width: 27px;
    height: 27px;
    cursor: ${props => props.status ? 'pointer' : 'none'};

    & svg {
      width: 27px;
      height: 27px;
      fill: ${({ theme }) => theme.primary};
    }
 }
`