// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import styled from "styled-components/macro";
// 
// ** UTILS **
import Api from "../../../contexts/Api";
import { useNotification } from "../../UseNotification";
import AlertModal from "../../Modals/Admin/AlertModal";
import EditReward from "./EditReward";
//
// ** COMPONENTS **
import { Reward, AdminComponent, Button } from "../../Style/StyledComponents";
import Loading from "../../Loading";
import Formfields from "../../Formfields";
import { breakpoints } from "../../Style/StyledBreakpoints";
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const RewardsList = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [rewards, setRewards] = useState([])
  const [displayForm, setDisplayForm] = useState({})
  const [show, setShow] = useState(false)
  const [openAlert, setOpenAlert] = useState({ open: false, reward_id: null })
  const [loading, setLoading] = useState(true)

  const { notification } = useNotification();

  // 
  // ─── GET REWARDS ───────────────────────────────────────
  //
  useEffect(() => {
    const getRewards = async () => {
      const data = await Api({
        endpoint: '/rewards',
        method: 'GET'
      });

      if (data.success) setRewards(data.data)
      setLoading(false)
    }
    if (!rewards.length) getRewards()
  }, [rewards])

  // 
  // ─── HANDLE DELETE REWARD ───────────────────────────────────────
  //
  const handleDelete = async (e) => {
    e.preventDefault();

    const handleDelete = await Api({
      data: { reward_id: openAlert.reward_id },
      endpoint: '/rewards',
      method: 'DELETE'
    })

    if (handleDelete.success) {
      updateRewardsList({ reward_id: openAlert.reward_id, delete: true })
      return notification({ variant: 'success', message: handleDelete.message })
    } else {
      notification({ variant: 'error', message: handleDelete.message })
    }
  }

  // 
  // ─── UPDATE REWARDS LIST (ADD / UPDATE / DELETE) ───────────────────────────────────────
  //
  const updateRewardsList = (opt) => {
    setLoading(true)
    let isNewReward = rewards.filter(reward => reward.reward_id === opt.reward_id)
    if (!isNewReward.length) {
      setRewards([...rewards, opt])
    } else if (opt.delete) {
      let newRewardsArray = rewards.filter(reward => reward.reward_id !== opt.reward_id)
      setRewards([...newRewardsArray])
      setShow(false)
      setDisplayForm({})
    } else {
      let indexReward = rewards.findIndex(reward => reward.reward_id === opt.reward_id)
      rewards[indexReward] = { ...rewards[indexReward], ...opt }
      setRewards([...rewards])
    }
    setLoading(false)
  }

  const defaultReward = rewards.length ? rewards.find(reward => reward.reward_id === displayForm) : {}

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <AdminComponent>
    <S.Title>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" /></svg>
      Récompenses
      <Button onClick={() => { setShow(true); setDisplayForm({}) }}>{window.innerWidth > 800 && 'Ajouter'}<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" /></svg></Button>
    </S.Title>
    <Formfields field={{ type: "select", component: "select", name: "rewards", admin: true, placeholder: 'Choisir le niveau', data: rewards ? rewards.map((reward) => { return { id: reward.reward_id, label: reward.label, points: reward.points, reward: reward.reward } }) : [], width: '100%', adminValue: !Object.keys(displayForm).length, required: true, onChange: (e) => { setShow(true); setDisplayForm(e) } }} />
    <Loading loading={loading}>
      {show && <EditReward reward={defaultReward} label={rewards.length + 1} onDelete={(reward_id) => setOpenAlert({ open: true, reward_id: reward_id })} onUpdate={updateRewardsList} onClose={() => { setShow(false); setDisplayForm({}) }} />}
    </Loading>
    {openAlert.open && <AlertModal handleConfirm={(e) => { handleDelete(e); setOpenAlert({ ...openAlert, open: false }); setShow(false) }} onClose={() => setOpenAlert({ open: false })} />}
  </AdminComponent>
};

export default RewardsList;

// 
// ─── STYLE DEFINITION ───────────────────────────────────────
//
const S = {}

S.Title = styled.div`
display: flex;
align-items: center;
gap: 10px;
color: ${({ theme }) => theme.textSecondary};;
font-size: 20px;
font-weight: 700;

& svg {
  fill: ${({ theme }) => theme.primary};
  width: 20px;
  height: 20px;
}

& button {
  margin-left: auto;
  width: fit-content;
  gap: 10px;
  ${breakpoints("padding", "", [
  { 800: "10px 15px" },
])};

  & svg {
    fill: ${({ theme }) => theme.secondary};
    width: 12px;
    height: 12px;
  }
}
`

S.Reward = styled(Reward)`
height: 44px;
padding: 0;
flex-direction: row;
padding-left: 50px;
max-width: ${({ theme }) => theme.width > 800 ? '15%' : '33%'};
`

S.Actions = styled.div`
display: flex;
gap: 5px;

& svg {
  width: 20px;
  height: 20px;
  fill: ${({ theme }) => theme.primary};
}
`