// 
// ─── IMPORT ───────────────────────────────────────
//
// ** NECESSARY ELEMENTS **
import { useState, useEffect } from "react";
import Cookies from 'universal-cookie';
// 
// ** UTILS **
import Api from "../contexts/Api";
// 
// ** COMPONENTS **
import { Container, EmptyContainer, Title } from "../components/Style/StyledComponents";
import ListMobile from "../components/Sponsors/Mobile/ListMobile";
import ListDesktop from "../components/Sponsors/Desktop/ListDesktop";
import SearchBar from "../components/SearchBar/SearchBar";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Sponsors = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [sponsored, setSponsored] = useState({ all: [], filtered: [] })
  const [offers, setOffers] = useState([])
  const [empty, setEmpty] = useState(false)
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── GET SPONSORED USERS ───────────────────────────────────────
  //
  useEffect(() => {
    const getSponsoredUsers = async () => {
      const data = await Api({
        endpoint: '/users',
        query: { sponsored_code: userData.sponsor_code },
        method: 'GET'
      });

      if (data.success) setSponsored({ all: data.data, filtered: [] })
      else setEmpty(true)
    }
    if (!sponsored.all.length) getSponsoredUsers()
  }, [sponsored])

  // 
  // ─── GET OFFERS' USERS TRACKING ───────────────────────────────────────
  //
  useEffect(() => {
    const getUsersOffers = async () => {
      const data = await Api({
        endpoint: '/offers_shared',
        query: { fields: ['offers.title', 'offers_shared.status', 'offers_shared.user_id', 'offers_applied.time_create', 'offers_applied.time_update'], join: true },
        method: 'GET'
      });

      if (data.success) setOffers(data.data)
    }
    if (!offers.length) getUsersOffers()
  }, [offers])

  const offersByUser = offers.reduce((acc, offer) => {
    if (!acc[offer.user_id]) {
      acc[offer.user_id] = [];
    }
    acc[offer.user_id].push(offer);
    return acc;
  }, {});

  // Set the value of the search bar
  //
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const handleSearch = (value) => {
    let search = sponsored.all.filter(user => {
      //search by nom prenom
      return (
        user.firstname.toLowerCase().includes(value) ||
        user.name.toLowerCase().includes(value)
      )
    })
    if (!search.length) search = [{ none: true }]
    setSponsored({ ...sponsored, filtered: search })
  }

  let sponsoredList = sponsored.all
  if (sponsored.filtered.length) {
    sponsoredList = sponsored.filtered
  }

  // Set filters if url
  let searchBar = ''

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Container>
    <Title>Mes parrainages</Title>
    {window.innerWidth > 800 && <SearchBar disabled={true} users={true} defaultSearch={searchBar} change={handleSearch} />}
    {sponsoredList.length &&
      window.innerWidth > 800 ? <ListDesktop sponsored={sponsoredList} offers={offersByUser} /> : <ListMobile sponsored={sponsoredList} offers={offersByUser} />}
    {empty && <EmptyContainer>
      <h1>
        Oops ...
      </h1>
      Pas de filleuls pour le moment.
    </EmptyContainer>}
  </Container>
};

export default Sponsors;